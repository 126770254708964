import React from "react";
import Layout from "../components/Layout";
import PageFull from "../components/PageFull";

const AboutMe = () => {
  return (
    <Layout description="Information about me, Ryan Mitchell">
      <PageFull title={{ rendered: "About me" }}>
        <div itemScope="" itemType="http://schema.org/Person">
          <img
            className="photo alignright"
            alt="Ryan Mitchell"
            src="/uploads/ryan-mitchell.gif"
            itemProp="image"
          />
          <p>
            I’m <span itemProp="name">Ryan Mitchell</span>, a{" "}
            <span itemProp="jobTitle">UI developer</span> specialising in
            JavaScript and HTML5. Alongside my technical skills I have extensive
            experience in{" "}
            <abbr title="globally distributed development">GDD</abbr>, track
            leading, source management (SVN/Git), client presentations and
            mentorship. I’ve developed web applications with responsive /
            adaptive designs, as well as mobile / tablet focused work.
          </p>
          <p>
            Through my company,{" "}
            <a href="http://daftapeth.co.uk">Daft Apeth Industries</a>, I’ve
            worked with various London agencies for top automotive and banking
            clients. Previously I spent five years working for Sapient Nitro
            with clients including Vodafone, McLaren, Unilever and Coca Cola.
          </p>
          <p>
            Visit my LinkedIn profile:{" "}
            <a href="http://uk.linkedin.com/in/ryandavidmitchell/">
              http://uk.linkedin.com/in/ryandavidmitchell/
            </a>
          </p>
          <h2>Expert Level Skills</h2>
          <ul className="bullets">
            <li>JavaScipt</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>Accessibility best practices</li>
          </ul>
          <h2>Technologies / Libraries</h2>
          <p>All used in client projects, not just demonstrations.</p>
          <ul className="bullets">
            <li>React, TypeScript</li>
            <li>MobX, Redux, Redux Sagas, Immutable.js</li>
            <li>OpenFin</li>
            <li>StoryBook</li>
            <li>IndexedDB</li>
          </ul>
        </div>
      </PageFull>
    </Layout>
  );
};

export default AboutMe;
